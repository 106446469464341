
const container = {
  height: '100vh', display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, padding: '50px'
};

const containerLogo = {
  display: "flex",
  flex: 1,
  justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
  alignItems: { xs: 'center', sm: 'center', md: 'flex-start' }
};

const containerForm = { display: "flex", flexDirection: 'column', flex: 1, paddingTop: { xs: '0px', sm: '0px', md: '168.62px' } };

const containerButton = {
  display: "flex", justifyContent: 'flex-end', paddingTop: { xs: '10px', sm: '10px', md: '0px' }
};

const subContainer = {
  display: "flex", flex: 1
};

const buttonStyle = {
  width: { xs: '100%', sm: '100%', md: '120px' }, backgroundColor: '#BC8D63', padding: '9px 41px', borderRadius: '10px'
};

export { container, containerLogo, containerForm, containerButton, buttonStyle, subContainer };