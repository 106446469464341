// Lib dependencies
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Style
import useStyles from './Select.styles';

const Select = ({ value, onChange, fullwidth, items, size, placeholder }) => {
  // Hooks
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{ option: classes.option }}
      fullWidth={fullwidth}
      size={size}
      value={value}
      onChange={onChange}
      options={items}
      renderInput={(params) => <TextField placeholder={placeholder} {...params} />}
    />
  );
};

export default Select;
