// Lib dependencies
import React from 'react';
import { Checkbox as NativeCheckbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

// Style
import { getCustomStyle } from './Checkbox.styles';

const Checkbox = ({ isChecked, onChange, label }) => {
  return (
    <FormControlLabel
      label={label}
      control={<NativeCheckbox sx={getCustomStyle} onChange={onChange} checked={isChecked} />}
    />
  );
};

export default Checkbox;
