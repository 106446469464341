import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => {
  const styles = {
    option: {
      "&:hover": {
        backgroundColor: "#F8F8FA !important"
      },
      "&.MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: "#D9AE8A !important"
      }
    }
  };

  return styles;
});
