// Lib dependencies
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// Services
import { getBrands, cleanup } from '../services/brand';


export const useBrand = () => {
  //State
  const [brands, setBrands] = useState([])
  const [error, setError] = useState(false)
  const [done, setDone] = useState(false)
  const [cleanupBody, setCleanupBody] = useState(null)

  // Get all brands
  useEffect(() => {
    const getBrandsApiCall = async () => {
      try {
        const brandList = await getBrands();
        const result = brandList.data.data.reduce((accumulator, currentValue) => {
          accumulator.push({ id: currentValue._id, label: currentValue.name, value: currentValue.name })
          return accumulator
        },
          [])
        setBrands(result)
      } catch (error) {
        setError(error)
        toast.error("Server Error", {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      }
    };
    getBrandsApiCall();
  }, [])

  // To Clean up
  useEffect(() => {
    if (cleanupBody) {
      setDone(false)
      const cleanupApiCall = async () => {
        try {
          await cleanup(cleanupBody);
          toast.success("Operation completed successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
          setDone(true)
        } catch (error) {
          setError(error)
          toast.error("Server Error", {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        }
      };

      cleanupApiCall();
      setDone(false)
    }
  }, [cleanupBody])


  return {
    brands,
    error,
    setCleanupBody,
    done
  };
};
