const ACTIONS = [
  { id: 1, label: 'Certificates', filter: 'certificates', isChecked: false },
  { id: 2, label: 'Final customers', filter: 'finalCustomers', isChecked: false },
  { id: 3, label: 'Products List', filter: 'products', isChecked: false },
  { id: 4, label: 'Wholesalers products List', filter: 'wholesalersProducts', isChecked: false },
  { id: 5, label: 'Certifications requests', filter: 'certificationRequests', isChecked: false },
  { id: 6, label: 'Care & Repair', filter: 'careandrepair', isChecked: false },
  { id: 7, label: 'Lock', filter: 'lock', isChecked: false },
]

export { ACTIONS }