import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => {
  const styles = {
  };

  return styles;
});

export const getCustomStyle = () => {
  return {
    '&.Mui-checked': {
      color: '#BC8D63',
    },
  };
};