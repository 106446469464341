// Lib dependencies
import { useState, useEffect } from 'react';

// UI lib dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// UI local dependencies
import Select from './components/Select'
import Checkbox from './components/Checkbox';

// SVG icons
import { ReactComponent as LogoTrustPlace } from './assets/svg/trustplace-logo.svg';

// Hooks
import { useBrand } from './hooks/useBrand'

// Style
import './App.css';
import { container, containerLogo, containerForm, containerButton, buttonStyle, subContainer } from './App.styles';

// Constants
import { ACTIONS } from './utils/constants/actions'


function App() {
  //State
  const [actionsList, setActionsList] = useState(ACTIONS)

  const [selectedBrand, setSelectedBrand] = useState(null)

  const { brands, setCleanupBody, done } = useBrand();

  const handleChange = (filter) => {

    let newActionsList = [...actionsList].map((action, index) => {
      if (filter === action.filter) {
        return { ...action, isChecked: !action.isChecked }
      }
      return action
    })
    if (newActionsList[0].isChecked === true) {
      newActionsList = [...newActionsList].map((action, index) => {
        if (action.filter === "finalCustomers") {
          return { ...action, isChecked: true }
        }
        return action
      })
    }

    setActionsList(newActionsList)
  };

  const handleValidate = () => {
    const filters = actionsList.filter((action) => action.isChecked).reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.filter)
      return accumulator
    }, [])
    const cleanupBody = { brand: selectedBrand.id, actions: filters }
    setCleanupBody(cleanupBody)
  };


  useEffect(() => {
    if (done) {
      setSelectedBrand(null)
      let newActionsList = [...actionsList].map((action, index) => {
        return { ...action, isChecked: false }
      })
      setActionsList(newActionsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done])


  return (
    <Box sx={container}>
      <Box sx={containerLogo}>
        <LogoTrustPlace />
      </Box>
      <Box sx={containerForm}>
        <Typography variant="h6" gutterBottom>
          Choose your brand
        </Typography>
        {brands ? <Select onChange={(event, values) => setSelectedBrand(values)} value={selectedBrand} placeholder={"Your brand"} size={"small"} items={brands} /> : null}
        <Typography variant="h6" gutterBottom>
          Choose your actions
        </Typography>
        {actionsList.map((action, index) => <Checkbox key={action.id}
          onChange={() => handleChange(action.filter)} label={action.label} isChecked={actionsList[index].isChecked} />)}
        <Box sx={containerButton}>
          <Button disabled={!selectedBrand ? true : false} onClick={handleValidate} variant="contained"
            sx={buttonStyle}>
            Submit
          </Button>
        </Box>
      </Box>
      <Box sx={subContainer}>
      </Box>
      <ToastContainer />
    </Box>
  );
}

export default App;
