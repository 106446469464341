import Api from './api';

const getBrands = () => {
  return Api.get('/brands');
};

const cleanup = (body) => {
  return Api.post('/', body);
};

export {
  getBrands,
  cleanup
};
